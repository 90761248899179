<template>
  <v-container class="broker-details pa-0 pt-7 relative" fluid>
    <!--    <Spinner-->
    <!--      v-if="loadingPolicies || loadingCoverageRequests"-->
    <!--      class="absolute-center"-->
    <!--    />-->
    <v-row class="broker-report">
      <v-col class="pb-4">
        <v-sheet class="top-level elevation-1">
          <v-row>
            <v-col>
              <v-container fluid class="pa-12">
                <v-row>
                  <v-col>
                    <h1
                      class="cursor-pointer white--text"
                      @click="showCoverageRequests()"
                    >
                      <span>
                        <i class="fad fa-file-invoice ml-1 aon-teal--text"></i>
                      </span>
                      {{ coverageRequestsCount }}
                    </h1>
                    <small
                      class="arrow cursor-pointer"
                      @click="showCoverageRequests()"
                    >
                      {{ $t('homeDetails.totalCoverageRequests') }}
                    </small>
                  </v-col>
                  <v-col shrink class="center-line"></v-col>
                  <v-col>
                    <h1
                      class="cursor-pointer white--text"
                      @click="$router.push({ name: 'Policies' })"
                    >
                      <span>
                        <i class="fad fa-file-certificate ml-1 success--text">
                        </i>
                      </span>
                      {{ policiesCount }}
                    </h1>
                    <small
                      class="arrow cursor-pointer"
                      @click="$router.push({ name: 'Policies' })"
                    >
                      {{ $t('homeDetails.totalPolicies') }}
                    </small>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
          </v-row>
        </v-sheet>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3" class="pr-2">
        <v-sheet class="detail-stats elevation-1">
          <div>
            <v-avatar
              size="56"
              color="aon-peacockLightest"
              class="cursor-pointer"
              @click="showCoverageRequests('SUBMITTED')"
            >
              <i class="fad fa-folder-open aon-peacock--text"></i>
            </v-avatar>
            <h1
              class="cursor-pointer"
              @click="showCoverageRequests('SUBMITTED')"
            >
              {{ gatheringInformationCount }}
            </h1>
            <small
              class="arrow cursor-pointer"
              @click="showCoverageRequests('SUBMITTED')"
            >
              {{ $t('homeDetails.gatheringInformation') }}
            </small>
          </div>
        </v-sheet>
      </v-col>
      <v-col cols="3" class="px-2">
        <v-sheet class="detail-stats elevation-1">
          <div>
            <v-avatar
              size="56"
              color="warningLightest"
              class="cursor-pointer"
              @click="showCoverageRequests('OUT_TO_MARKET')"
            >
              <i class="fad fa-shipping-timed warning--text"></i>
            </v-avatar>
            <h1
              class="cursor-pointer"
              @click="showCoverageRequests('OUT_TO_MARKET')"
            >
              {{ outToMarketCount }}
            </h1>
            <small
              class="arrow cursor-pointer"
              @click="showCoverageRequests('OUT_TO_MARKET')"
            >
              {{ $t('homeDetails.outToMarket') }}
            </small>
          </div>
        </v-sheet>
      </v-col>
      <v-col cols="3" class="px-2">
        <v-sheet class="detail-stats elevation-1">
          <div>
            <v-avatar
              size="56"
              color="aon-tealLightest"
              class="arrow cursor-pointer"
              @click="showCoverageRequests('APPLICANT_REVIEW')"
            >
              <i class="fad fa-search-dollar aon-teal--text"></i>
            </v-avatar>
            <h1
              class="arrow cursor-pointer"
              @click="showCoverageRequests('APPLICANT_REVIEW')"
            >
              {{ applicantReviewCount }}
            </h1>
            <small
              class="arrow cursor-pointer"
              @click="showCoverageRequests('APPLICANT_REVIEW')"
            >
              {{ $t('homeDetails.applicantReview') }}
            </small>
          </div>
        </v-sheet>
      </v-col>
      <v-col cols="3" class="pl-2">
        <v-sheet class="detail-stats elevation-1">
          <div>
            <v-avatar
              size="56"
              color="successLightest"
              class="arrow cursor-pointer"
              @click="showCoverageRequests('FINALIZE')"
            >
              <i class="fad fa-file-signature success--text"></i>
            </v-avatar>
            <h1
              class="arrow cursor-pointer"
              @click="showCoverageRequests('FINALIZE')"
            >
              {{ finalizeCount }}
            </h1>
            <small
              class="arrow cursor-pointer"
              @click="showCoverageRequests('FINALIZE')"
            >
              {{ $t('homeDetails.finalize') }}
            </small>
          </div>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import { getCoverageRequestPolicies } from '@/api/insurancePolicies';
import { mapState } from 'vuex';
import {
  SUBMITTED,
  OUT_TO_MARKET,
  APPLICANT_REVIEW,
  FINALIZE,
  COMPLETED,
} from '@aon/cfs-utils/lib/constants/insuranceApplicationStatuses';

export default {
  name: 'Details',
  data() {
    return {
      policies: null,
      loadingPolicies: true,
      policiesError: false,
      loadingCoverageRequests: true,
      coverageRequestsError: false,
    };
  },
  computed: {
    ...mapState('coverageRequests', ['coverageRequests']),
    policiesCount() {
      return (this.policies || []).length;
    },
    coverageRequestsCount() {
      return (this.coverageRequests || []).filter(
        request => request.status !== COMPLETED
      ).length;
    },
    gatheringInformationCount() {
      return (this.coverageRequests || []).filter(
        request => request.status === SUBMITTED
      ).length;
    },
    outToMarketCount() {
      return (this.coverageRequests || []).filter(
        request => request.status === OUT_TO_MARKET
      ).length;
    },
    applicantReviewCount() {
      return (this.coverageRequests || []).filter(
        request => request.status === APPLICANT_REVIEW
      ).length;
    },
    finalizeCount() {
      return (this.coverageRequests || []).filter(
        request => request.status === FINALIZE
      ).length;
    },
  },
  created() {
    this.getPolicies();
    // this.getCoverageRequests();
  },
  methods: {
    async getPolicies() {
      this.loadingPolicies = true;
      this.policiesError = false;

      try {
        // const { data } = await getCoverageRequestPolicies();
        this.policies = [];
      } catch (error) {
        this.$logger.error(error);
        this.policiesError = true;
      }

      this.loadingPolicies = false;
    },
    // async getCoverageRequests() {
    //   this.loadingCoverageRequests = true;
    //   this.coverageRequestsError = false;
    //
    //   try {
    //     await this.$store.dispatch('coverageRequests/getCoverageRequests');
    //   } catch {
    //     this.coverageRequestsError = true;
    //   }
    //
    //   this.loadingCoverageRequests = false;
    // },
    showCoverageRequests(status) {
      const path = {
        name: 'Coverage Requests',
      };

      if (status) {
        path.query = { status };
      }

      this.$router.push(path);
    },
  },
};
</script>

<style lang="scss">
.broker-details {
  min-width: 800px;
  position: relative;

  .top-level {
    background: linear-gradient(182.01deg, #012774 0%, #001135 100%);
    box-shadow: 0px 0px 1px rgba(0, 22, 67, 0.04),
      0px 4px 8px rgba(0, 22, 67, 0.04), 0px 1rem 24px rgba(0, 22, 67, 0.04),
      0px 24px 32px rgba(0, 22, 67, 0.04);
    border-radius: $sheet-border-radius;

    .col {
      position: relative;
      min-width: 220px;
      color: $white;

      > div {
        margin: auto;
        text-align: center;
      }

      div {
        h1 {
          display: inline-block;
          margin-bottom: 0;

          span {
            vertical-align: text-bottom;

            i {
              font-size: 1.5rem;
            }
          }
        }

        small {
          display: block;
          padding-left: 1.25em;
        }
      }

      .center-line {
        min-width: 1px;
        max-width: 1px;
        padding: 0;
        background: #283a69;
      }
    }
  }

  .detail-stats {
    display: flex;
    padding: 2em 0;
    text-align: center;

    > div {
      margin: auto;

      i {
        font-size: 1.5em;
      }

      h1 {
        margin-bottom: 4px;
      }
    }
  }

  small {
    font-size: 1rem;
    text-decoration: underline;

    &.arrow::after {
      content: '\003e';
      margin-left: 0.75em;
      text-decoration: none;
    }
  }
}
</style>
