<template>
  <div class="home">
    <Spinner v-if="loading" />
    <Details v-else />
  </div>
</template>

<script>
import Details from '@/components/Home/Details';

export default {
  name: 'Home',
  components: {
    Details,
  },
  data() {
    return {
      loading: true,
    };
  },
  async created() {
    this.loading = false;
  },
  methods: {
    viewDetails(targets) {
      const target = targets[0];
      this.$router.push(`coverage-requests/${target.targetLocation}`);
    },
  },
};
</script>
